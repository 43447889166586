import AiAvatar from "~/assets/AiAvatar.svg";
import UserAvatar from "~/assets/UserAvatar.svg";
import styles from "./ConversationAvatarIcon.module.css";

export const ConversationAvatarIcon = ({ alt, src }: P): JSX.Element => (
  <img alt={alt} className={styles.chatAvatarIcon} src={src} />
);

export const AI_AVATAR_ALT_VALUE = "AI Avatar";
export const AiAvatarIcon = (): JSX.Element => (
  <ConversationAvatarIcon alt={AI_AVATAR_ALT_VALUE} src={AiAvatar} />
);

export const USER_AVATAR_ALT_VALUE = "User Avatar";
export const UserAvatarIcon = (): JSX.Element => (
  <ConversationAvatarIcon alt={USER_AVATAR_ALT_VALUE} src={UserAvatar} />
);

export interface ConversationAvatarIconProps {
  alt: string;
  src: string;
}

type P = ConversationAvatarIconProps;
