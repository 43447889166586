import {
  type Citation,
  type ChatMessage,
  type ToolMessageContent,
} from "~/api";
import { EMPTY_ARRAY } from "~/constants";

export const parseCitationFromMessage = (
  message: ChatMessage,
): Citation[] | readonly never[] => {
  if (message.role === "tool") {
    try {
      const toolMessage = JSON.parse(message.content) as ToolMessageContent;
      return toolMessage.citations;
    } catch {
      return EMPTY_ARRAY;
    }
  }
  return EMPTY_ARRAY;
};
