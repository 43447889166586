import { useContext } from "react";
import { globalContext } from "~/context";

import styles from "./HiddenTextForGTranslate.module.css";
import { type COND_RENDERED_TEXT_KEY } from "~/hooks/useConditionallyRenderedText";

export const HiddenTextForGTranslate = (): JSX.Element => {
  const { conditionallyRenderedText } = useContext(globalContext);

  return (
    <>
      {Object.keys(conditionallyRenderedText).map((key) => (
        <span
          aria-hidden={true}
          className={styles.hiddenTextForGTranslate}
          key={key}
          ref={conditionallyRenderedText[key as COND_RENDERED_TEXT_KEY].ref}
        >
          {conditionallyRenderedText[key as COND_RENDERED_TEXT_KEY].text}
        </span>
      ))}
    </>
  );
};
