import { useEffect, useState } from "react";

import type { AppContextType } from "~/context";
import { useConditionallyRenderedText } from "./useConditionallyRenderedText";

export const useGlobalState = (): AppContextType => {
  const [isScreen1080, setIsScreen1080] = useState(window.innerWidth <= 1080);
  const [isScreen760, setIsScreen760] = useState(window.innerWidth <= 750);
  const [shouldDisplayHeader, setShouldDisplayHeader] = useState<boolean>(true);

  const conditionallyRenderedText = useConditionallyRenderedText();

  useEffect(() => {
    const handleResize = (): void => {
      setIsScreen1080(window.innerWidth <= 1080);
      setIsScreen760(window.innerWidth <= 760);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    conditionallyRenderedText,
    isScreen1080,
    isScreen760,
    shouldDisplayHeader,
    setShouldDisplayHeader,
  };
};
