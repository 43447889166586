import styles from "./LoadAnimation.module.css";


interface Props {
  right?: string;
}


export const LoadAnimation = ({right = "0px"}: Props): JSX.Element => {

  return (
    <div
      aria-label="Loading..."
      aria-hidden="true"
      className={styles.loadingAnimationContainer}>
      <div
        style={{right}}
        className={styles.loadingChatAnimation}>
      </div>
    </div>
  );
};
