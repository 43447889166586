import { type HyperlinkProps } from "./Hyperlink.types";

import styles from "./Hyperlink.module.css";

export const REL_ATTR = { NAME: "rel", VALUE: "noopener noreferrer" };
export const TARGET_ATTR = { NAME: "target", VALUE: "_blank" };

export const Hyperlink = ({
  children = "",
  isTargetBlank = false,
  ...rest
}: HyperlinkProps): JSX.Element => {
  const options = {
    ...(isTargetBlank
      ? {
          [TARGET_ATTR.NAME]: TARGET_ATTR.VALUE,
        }
      : {}),
    [REL_ATTR.NAME]: REL_ATTR.VALUE,
  };

  return (
    <a className={styles.hyperlink} {...options} {...rest}>
      {children}
    </a>
  );
};
