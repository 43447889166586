import { HEADERS, MIME_TYPES, REQUEST_METHOD } from "./api.const";

export const makeRequest = async (
  url: string,
  init: RequestInit,
): Promise<Response> => {
  try {
    const response = await fetch(url, init);

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const createPostInit = (body: {
  recaptcha_token: string;
}): RequestInit => ({
  method: REQUEST_METHOD.POST,
  headers: {
    Accept: MIME_TYPES.APPLICATION_JSON,
    [HEADERS.CONTENT_TYPE]: MIME_TYPES.APPLICATION_JSON,
  },
  body: JSON.stringify(body),
});
