import { useEffect, useState } from "react";
import { getUserInfo } from "~/api";
import { AUTH_PAGE_ENABLED } from "~/config/config";

export const useShowAuthMessage = (): boolean => {
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);

  useEffect(() => {
    if (AUTH_PAGE_ENABLED) {
      const getUserInfoList = async (): Promise<void> => {
        const userInfoList = await getUserInfo();
        if (userInfoList.length === 0) {
          setShowAuthMessage(true);
        } else {
          setShowAuthMessage(false);
        }
      };

      void getUserInfoList();
    }
  }, []);

  return showAuthMessage;
};
