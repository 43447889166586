import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import { initGA4 } from "~/analytics";
import { globalContext } from "~/context";
import { useGlobalState } from "~/hooks";

import {
  DisclaimerIntoModal,
  ReCaptcha,
  HiddenTextForGTranslate,
} from "~/components";
import { Chat, Layout, NoPage } from "~/pages";

import "./index.css";
import "./styles/variables/_variables.css";
import "semantic-ui-css/semantic.min.css";

initializeIcons();

declare global {
  interface Window {
    grecaptcha: any;
    siteKey: string;
  }
}

const App = (): JSX.Element => {
  const state = useGlobalState();
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    initGA4();
    setForceRender(true);
  }, []);

  return (
      <globalContext.Provider value={state}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Chat />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </HashRouter>
        {forceRender && <DisclaimerIntoModal />}
        <ReCaptcha />
        <HiddenTextForGTranslate />
      </globalContext.Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
