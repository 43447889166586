import { Stack } from "@fluentui/react";
import { ShieldLockRegular } from "@fluentui/react-icons";

import { Hyperlink } from "~/components";

import styles from "./AuthMessage.module.css";
import chatStyles from "~/pages/chat/Chat.module.css";

export const AuthMessage = (): JSX.Element => (
  <div className={chatStyles.container} role="main">
    <Stack className={chatStyles.chatEmptyState}>
      <ShieldLockRegular className={styles.authMessageIcon} />
      <h1 className={chatStyles.chatTitle}>
        MyCity Chatbot Authentication Not Configured
      </h1>
      <p className={chatStyles.chatEmptyStateIntro}>
        This app does not have authentication configured. Please add an identity
        provider by finding your app in the{" "}
        <Hyperlink href="https://portal.azure.com/" isTargetBlank>
          Azure Portal
        </Hyperlink>
        and following{" "}
        <Hyperlink
          href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization"
          isTargetBlank
        >
          these instructions
        </Hyperlink>
        .
      </p>
      <p className={chatStyles.chatEmptyStateIntro}>
        <strong>
          Authentication configuration takes a few minutes to apply.{" "}
        </strong>
      </p>
      <p className={styles.authMessageSubtitle}>
        <strong>
          If you deployed in the last 10 minutes, please wait and reload the
          page after 10 minutes.
        </strong>
      </p>
    </Stack>
  </div>
);
