import { v4 as uuidv4 } from "uuid";

export class SessionStorage {
  private readonly _sessionUUID: string;

  constructor() {
    if (!sessionStorage.getItem("sessionUUID")) {
      this._sessionUUID = uuidv4();
      sessionStorage.setItem("sessionUUID", this._sessionUUID);
    } else {
      this._sessionUUID = sessionStorage.getItem("sessionUUID") ?? "";
    }
  }

  get sessionUUID(): string {
    return this._sessionUUID;
  }
}
