import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { FontIcon, Stack, Text } from "@fluentui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";

import { type AskResponse, ChatMessage, type Citation, Feedback, feedbackApi } from "~/api";
import { Svg } from "~/components/Svg";
import { globalContext } from "~/context";
import { COND_RENDERED_TEXT_KEY } from "~/hooks";
import { parseAnswer } from "./AnswerParser";
import {
  thumbDownPathD,
  thumbSvgHeight,
  thumbSvgViewbox,
  thumbSvgWidth,
  thumbUpPathD,
} from "./Answer.const";

import styles from "./Answer.module.css";
import ExternalLinkRef from "~/assets/ExternalLinkRef.svg";
import FeedbackModal from "../FeedbackModal/FeedbackModal";
import { LoadAnimation } from "../LoadAnimation";
import config from "~/config/config";

interface Props {
    answer: AskResponse;
    answers?: ChatMessage[];
    answerIndex?: number;
    chatUUID: string;
    isLoading?: boolean;
    isStreamComplete?: boolean;
    onCitationClicked: (citedDocument: Citation) => void;
    onRenderedAnswersUpdate?: (answers: ChatMessage[]) => void;
    referencesText?: string;
    referenceText?: string;
    sessionUUID: string;
}

export const Answer = ({
                           answer,
                           answers = [],
                           answerIndex,
                           chatUUID,
                           isLoading,
                           isStreamComplete = false,
                           onCitationClicked,
                           onRenderedAnswersUpdate,
                           referencesText,
                           referenceText,
                           sessionUUID,
                       }: Props): JSX.Element => {
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
        useBoolean(true);
    const filePathTruncationLimit = 50;
    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const markDownText = parsedAnswer.markdownFormatText;
    const [chevronIsExpanded, setChevronIsExpanded] =
        useState(isRefAccordionOpen);
    const [renderedText, setRenderedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isWriting, setIsWriting] = useState(false);
    const [isFeedbackSent, setIsFeedbackSent] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFeedbackPositive, setisFeedbackPositive] = useState(true);
    const { conditionallyRenderedText } = useContext(globalContext);

    const sendFeedback = useCallback(
        async (feedback?: Feedback) => {
            if (isFeedbackSent) return false;

            setIsFeedbackSent(true);
            const thumbFeedback = isFeedbackPositive ? "Thumb Up" : "Thumb Down";

            try {
                const recaptchaToken = await window.grecaptcha.execute(window.siteKey, {
                    action: "submit",
                });

                const gRecaptchaResponse = document.getElementsByName(
                    "g-recaptcha-response",
                )[0] as HTMLInputElement;
                gRecaptchaResponse.value = recaptchaToken;

                await feedbackApi({
                    chatUUID,
                    message: answer.answer,
                    recaptchaToken,
                    sessionUUID,
                    thumbFeedback,
                    userFeedback: feedback,
                });
                return true;
            } catch (error) {
                console.error("Error reporting thumb up/down feedback", error);
                setIsFeedbackSent(false);
                return false;
            }
        },
        [answer.answer, chatUUID, isFeedbackSent, sessionUUID, isFeedbackPositive, setIsFeedbackSent],
    );

    const openFeedbackModal = (isFeedbackPositive: boolean): void => {
        if (isFeedbackSent) {
            return;
        }

        setisFeedbackPositive(isFeedbackPositive);
        setIsModalOpen(true);
    };

    const handleChevronClick = (): void => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    useEffect(() => {
        const renderTextGradually = (): void => {
            const textToRender = markDownText;

            if (currentIndex < textToRender.length) {
                setRenderedText(textToRender.substring(0, currentIndex + 1));
                setCurrentIndex(currentIndex + 1);
                setIsWriting(true);
            } else {
                isLoading
                    ? setRenderedText(
                        textToRender.substring(0, currentIndex + 1),
                    )
                    : setRenderedText(textToRender.substring(0, currentIndex + 1) + "");
                setIsWriting(false);
                setIsAnswerRendering(false);
            }
        };

        const intervalId = setInterval(renderTextGradually, 5);

        return () => {
            clearInterval(intervalId);
        };
    }, [markDownText, currentIndex]);

    const setIsAnswerRendering = (isRendering: boolean) => {
        if (answerIndex
            && answers.length
            && onRenderedAnswersUpdate
            && isRendering !== answers[answerIndex].isAnswerRendering
        ) {
            answers[answerIndex].isAnswerRendering = isRendering;
            onRenderedAnswersUpdate(answers);
        }

    };

    const createCitationFilepath = (
        citation: Citation,
        index: number,
        truncate: boolean = false,
    ): string => {
        let citationFilename = "";

        if (citation?.filepath) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(
                    0,
                    20,
                )}...${citation.filepath.substring(citationLength - 20)}`;
            } else {
                citationFilename = `${citation.filepath}`;
            }
        } else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    };

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                {isWriting && (
                    <div style={{ visibility: "hidden", position: "absolute" }}>
                        Processing response
                    </div>
                )}
                <Stack.Item aria-hidden={isWriting} grow>
                    <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        // eslint-disable-next-line react/no-children-prop
                        children={renderedText}
                        className={styles.answerText}
                    />
                </Stack.Item>
                {(!isStreamComplete || isWriting) && (
                    <Stack.Item className={styles.answerInStreamFooter}>
                        <div className={styles.disclaimerContainer}>
                            <div className={styles.loadingAnimationContainer}>
                                <LoadAnimation right="-16px"/>
                            </div>
                            <div className={styles.disclaimer} style={{textAlign: "left"}}>
                                {
                                    conditionallyRenderedText[COND_RENDERED_TEXT_KEY.VERIFY_TEXT].ref?.current?.textContent
                                    ??
                                    conditionallyRenderedText[COND_RENDERED_TEXT_KEY.VERIFY_TEXT].text
                                }
                                {" "}
                                <a href={config.NYC_LANDING_PAGE} target="_blank">NYC.gov</a>
                                {" "}
                                {
                                    conditionallyRenderedText[COND_RENDERED_TEXT_KEY.AND].ref?.current?.textContent
                                    ??
                                    conditionallyRenderedText[COND_RENDERED_TEXT_KEY.AND].text
                                }
                                {" "}
                                <a href={config.businessPage} target="_blank">MyCity Business</a>.
                            </div>
                        </div>
                    </Stack.Item>
                )}
                <Stack horizontal className={styles.answerFooter}>
                    {!(parsedAnswer.citations.length === 0) && (
                        <Stack.Item>
                            <Stack style={{ width: "100%" }}>
                                <Stack
                                    horizontal
                                    horizontalAlign="start"
                                    verticalAlign="center"
                                >
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                    <span>
                      {parsedAnswer.citations.length > 1
                          ? parsedAnswer.citations.length +
                          " " +
                          (referencesText ?? "")
                          : referenceText ?? ""}
                    </span>
                                    </Text>
                                    <FontIcon
                                        className={styles.accordionIcon}
                                        onClick={handleChevronClick}
                                        iconName={
                                            chevronIsExpanded ? "ChevronDown" : "ChevronRight"
                                        }
                                    />
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack>
                {(isStreamComplete && !isWriting) &&
                    <div className={styles.disclaimerContainer}>
                        <div className={styles.disclaimer}>
                            {
                                conditionallyRenderedText[COND_RENDERED_TEXT_KEY.DOUBLE_CHECK].ref?.current?.textContent
                                ??
                                conditionallyRenderedText[COND_RENDERED_TEXT_KEY.DOUBLE_CHECK].text
                            }
                            {" "}
                            <a href={config.NYC_LANDING_PAGE} target="_blank">NYC.gov</a>
                            {" "}
                            {
                                conditionallyRenderedText[COND_RENDERED_TEXT_KEY.AND].ref?.current?.textContent
                                ??
                                conditionallyRenderedText[COND_RENDERED_TEXT_KEY.AND].text
                            }
                            {" "}
                            <a href={config.businessPage} target="_blank">MyCity Business</a>.
                        </div>
                    </div>
                }
                {chevronIsExpanded && (
                    <div
                        className={styles.citationsWrapper}
                        style={{
                            display: parsedAnswer.citations.length > 0 ? "flex" : "none",
                        }}
                    >
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <div
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => {
                                        onCitationClicked(citation);
                                    }}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>
                                        {createCitationFilepath(citation, idx, true)}
                                    </div>
                                    <img
                                        src={ExternalLinkRef}
                                        className={styles.externalLinkIcon}
                                        aria-hidden="true"
                                        alt="External Link"
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
                {answer.answer !==
                    (conditionallyRenderedText[COND_RENDERED_TEXT_KEY.GENERATING_ANSWER]
                            .ref?.current?.textContent ??
                        conditionallyRenderedText[COND_RENDERED_TEXT_KEY.GENERATING_ANSWER]
                            .text) && !isWriting && isStreamComplete && (
                        <div className={styles.feedback}>
            <span id={String(answerIndex)} className={styles.title}>
              {conditionallyRenderedText[COND_RENDERED_TEXT_KEY.RESPONSE_HELPFUL]
                      .ref?.current?.textContent ??
                  conditionallyRenderedText[COND_RENDERED_TEXT_KEY.RESPONSE_HELPFUL]
                      .text}
            </span>
                            <div className={styles.feedbackButtonContainer} role="group"
                                 aria-labelledby={String(answerIndex)}>
                                <button
                                    role="button"
                                    className={styles.thumbButton}
                                    aria-pressed={isFeedbackSent && isFeedbackPositive}
                                    disabled={isFeedbackSent}
                                    onClick={() => openFeedbackModal(true)}
                                >
                                    <Svg
                                        classNameActive={styles.feedbackIconSelected}
                                        classNameBase={styles.feedbackIcon}
                                        classNameUnSelected={styles.feedbackIconUnselected}
                                        height={thumbSvgHeight}
                                        isActive={isFeedbackSent && isFeedbackPositive}
                                        pathD={thumbUpPathD}
                                        unSelected={isFeedbackSent && !isFeedbackPositive}
                                        viewBox={thumbSvgViewbox}
                                        width={thumbSvgWidth}
                                    />
                                    <span>
                  {conditionallyRenderedText[COND_RENDERED_TEXT_KEY.YES]
                          .ref?.current?.textContent ??
                      conditionallyRenderedText[COND_RENDERED_TEXT_KEY.YES]
                          .text}
                </span>
                                </button>
                                <button
                                    role="button"
                                    className={styles.thumbButton}
                                    aria-pressed={isFeedbackSent && !isFeedbackPositive}
                                    disabled={isFeedbackSent}
                                    onClick={() => openFeedbackModal(false)}
                                >
                                    <Svg
                                        classNameActive={styles.feedbackIconSelected}
                                        classNameBase={styles.feedbackIcon}
                                        classNameUnSelected={styles.feedbackIconUnselected}
                                        height={thumbSvgHeight}
                                        isActive={isFeedbackSent && !isFeedbackPositive}
                                        pathD={thumbDownPathD}
                                        unSelected={isFeedbackSent && isFeedbackPositive}
                                        viewBox={thumbSvgViewbox}
                                        width={thumbSvgWidth}
                                    />
                                    <span>
                {conditionallyRenderedText[COND_RENDERED_TEXT_KEY.NO]
                        .ref?.current?.textContent ??
                    conditionallyRenderedText[COND_RENDERED_TEXT_KEY.NO]
                        .text}
                </span>
                                </button>
                            </div>
                            {isModalOpen && (
                                <FeedbackModal sendFeedback={sendFeedback} isFeedbackPositive={isFeedbackPositive}/>
                            )}
                        </div>
                    )}
            </Stack>
        </>
    );
};
