export enum REQUEST_METHOD {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

export const HEADERS = {
  CONTENT_TYPE: "Content-Type",
};

export const MIME_TYPES = {
  APPLICATION_JSON: "application/json",
};

export const GET_REQUEST_INIT: RequestInit = {
  method: REQUEST_METHOD.GET,
  headers: {
    [HEADERS.CONTENT_TYPE]: MIME_TYPES.APPLICATION_JSON,
  },
};

export const AUTH_REQUEST_PATH = "/.auth/me";
export const CONVERSATION_REQUEST_PATH = "/conversation";
export const FEEDBACK_REQUEST_PATH = "/feedback";
export const RECAPTCHA_SITE_KEY_REQUEST_PATH = "/get_recaptcha_site_key";
