import { useCallback, useState } from "react";
import cx from "classnames";

import { Stack } from "@fluentui/react";
import TextareaAutosize from "react-textarea-autosize";

import Send from "~/assets/Send.svg";
import SendDisabled from "~/assets/SendDisabled.svg";

import styles from "./QuestionInput.module.css";
import { Popup } from "semantic-ui-react";
import { LoadAnimation } from "../LoadAnimation";

export interface QuestionInputProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  onSend: (question: string) => Promise<void>;
  placeholder?: string;
  shouldClearOnSend?: boolean;
  style?: React.CSSProperties;
  isStillAnimating?: boolean;
}

export const QuestionInput = ({
  isDisabled = false,
  isLoading = false,
  placeholder,
  onSend,
  shouldClearOnSend = false,
  style,
  isStillAnimating,
}: QuestionInputProps): JSX.Element => {
  const [question, setQuestion] = useState<string>("");
  const isSendQuestionButtonDisabled =
    isDisabled || isLoading || isStillAnimating || !question.trim();

  const sendQuestion = async (): Promise<void> => {
    if (isSendQuestionButtonDisabled) {
      return;
    }
    
    if (shouldClearOnSend) {
      setQuestion("");
    }
    await onSend(question);

  };

  const onEnterPress = async (ev: React.KeyboardEvent<Element>): Promise<void> => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      await sendQuestion();
    }
  };

  const onQuestionChange = useCallback(
    (_ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      setQuestion(_ev.target.value ?? "");
    },
    [setQuestion],
  );

  const popupStyle = {
    borderRadius: "8px",
    fontSize: "16px",
    paddingTop: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "8px",
  };

  return (
    <Stack horizontal className={styles.questionInputContainer} style={style}>
      <TextareaAutosize
        className={styles.questionInputTextArea}
        placeholder={placeholder}
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
        maxRows={6}
      />
      {isLoading ? (
        <LoadAnimation right="24px"/>
      ) : (
        <Popup
          trigger={
            <button
              aria-label="Send question"
              className={cx(styles.questionInputSendButton, {
                [styles.questionInputSendButtonDisabled]:
                  isSendQuestionButtonDisabled,
              })}
              disabled={isSendQuestionButtonDisabled}
              onClick={sendQuestion}
              onKeyDown={async (e) => {
                if (e.key === "Enter" || e.key === " ") await sendQuestion();
              }}
              role="button"
              tabIndex={0}
            >
              <img
                src={isSendQuestionButtonDisabled ? SendDisabled : Send}
                alt={isSendQuestionButtonDisabled ? "Send Disabled" : "Send"}
              />
            </button>
          }
          on="hover"
          inverted
          content="Send message"
          position="bottom center"
          style={popupStyle}
        />
      )}
    </Stack>
  );
};
