import React from 'react';

const UncheckedBoxSVG = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2.00098" width="36" height="36" fill="white" />
    <rect
      x="2"
      y="2.00098"
      width="36"
      height="36"
      stroke="#CC4466"
      strokeWidth="4"
    />
  </svg>
);

export default UncheckedBoxSVG;