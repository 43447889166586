import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";

import Close from "~/assets/Close.svg";

import styles from "./FeedbackModal.module.css";
import { type DivRefs, type Feedback, type InputRefs } from "~/api";
import CheckedBoxSVG from "./CheckedBoxSVG";
import UncheckedBoxSVG from "./UncheckedBoxSVG";

const ALERT_DIALOG = "alertdialog";
const ARIA_DESCRIBED_BY = "dialog_desc";
const ARIA_LABELED_BY = "dialog_label";

Modal.setAppElement("#root");

interface FeedbackModalProps {
  isFeedbackPositive: boolean;
  sendFeedback: (feedback?: Feedback) => any;
}

const FeedbackModalComponent = ({
  isFeedbackPositive = true,
  sendFeedback,
}: FeedbackModalProps): JSX.Element => {
  const inputRefs: InputRefs = {
    responseHarmful: useRef<HTMLInputElement | null>(null),
    responseFalse: useRef<HTMLInputElement | null>(null),
    responseUnhelpful: useRef<HTMLInputElement | null>(null),
    responseReferenceIssue: useRef<HTMLInputElement | null>(null),
  };

  const divRefs: DivRefs = {
    responseHarmful: useRef<HTMLDivElement | null>(null),
    responseFalse: useRef<HTMLDivElement | null>(null),
    responseUnhelpful: useRef<HTMLDivElement | null>(null),
    responseReferenceIssue: useRef<HTMLDivElement | null>(null),
  };

  const initialCheckboxState = {
    responseHarmful: false,
    responseFalse: false,
    responseUnhelpful: false,
    responseReferenceIssue: false,
  };

  const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [xOffSet, setXOffSet] = useState<number>(0);
  const [yOffSet, setYOffSet] = useState<number>(0);
  const [comment, setComment] = useState("");
  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxState);
  const [modalHeight, setModalHeight] = useState("80vh");

  useEffect(() => {
    const {
      height = 300,
      right = 400,
      width = 400,
    } = contentEl?.getBoundingClientRect() ?? {};

    const { width: parentWidth = width } =
      contentEl?.parentElement?.getBoundingClientRect() ?? {};

    if (right > width && parentWidth > width) {
      setXOffSet(Math.round(width / 2));
    }
    setYOffSet(Math.round(height / 2));

    function handleResize(): void {
      const windowHeight = window.innerHeight;
      const newHeight = `${windowHeight * 0.8}px`;
      setModalHeight(newHeight);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contentEl]);

  useEffect(() => {
    Object.keys(inputRefs).forEach((key) => {
      const inputRef = inputRefs[key as keyof InputRefs];
      const divRef = divRefs[key as keyof InputRefs];

      if (inputRef.current) {
        inputRef.current.addEventListener("focus", () => {
          if (divRef.current) {
            divRef.current.setAttribute("data-focused", "");
          }
        });

        inputRef.current.addEventListener("blur", () => {
          if (divRef.current) {
            divRef.current.removeAttribute("data-focused");
          }
        });
      }
    });

    return () => {
      Object.keys(inputRefs).forEach((key) => {
        const inputRef = inputRefs[key as keyof InputRefs];
        if (inputRef.current) {
          inputRef.current.removeEventListener("focus", (): void => {});
          inputRef.current.removeEventListener("blur", (): void => {});
        }
      });
    };
  }, [inputRefs, divRefs]);

  const handleSubmit = (event: any, submittedFeedback = true): void => {
    event.preventDefault();
    if (submittedFeedback) {
      const feedback = generateFeedback();
      void sendFeedback(feedback);
    } else {
      void sendFeedback();
    }
    setIsOpen(false);
    document.body.classList.remove("body-no-scroll");
  };

  const generateFeedback = (): Feedback => {
    const feedback: Feedback = {
      comment,
      responseHarmful: checkboxValues.responseHarmful,
      responseFalse: checkboxValues.responseFalse,
      responseReferenceIssue: checkboxValues.responseReferenceIssue,
      responseUnhelpful: checkboxValues.responseUnhelpful,
    };

    return feedback;
  };

  const handleTextareaChange = (event: any): void => {
    setComment(event.target.value);
  };

  const handleCheckboxChange = (event: any): void => {
    const { name, checked } = event.target;
    setCheckboxValues((prevCheckboxValues) => ({
      ...prevCheckboxValues,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const {
      height = 300,
      right = 400,
      width = 400,
    } = contentEl?.getBoundingClientRect() ?? {};

    if (right > width) {
      setXOffSet(Math.round(width / 2));
    }
    setYOffSet(Math.round(height / 2));
  }, [contentEl]);

  return (
    <Modal
      aria-describedby={ARIA_DESCRIBED_BY}
      aria-labelledby={ARIA_LABELED_BY}
      className={styles.modalView}
      id={ALERT_DIALOG}
      isOpen={isOpen}
      onRequestClose={handleSubmit}
      overlayClassName={styles.modalOverlay}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      contentRef={(node) => {
        setContentEl(node);
      }}
      role={ALERT_DIALOG}
      style={{ content: { margin: `-${yOffSet}px 0 0 -${xOffSet}px` } }}
    >
      <div className={styles.modalContent} style={{ height: modalHeight }}>
        <div className={styles.modalHeader}>
          <h2 id={ARIA_LABELED_BY} className={styles.modalHeaderText}>
            Additional feedback
          </h2>
          <button
            className={styles.modalClose}
            name="Modal Close"
            onClick={(event) => {
              handleSubmit(event, false);
            }}
            role="button"
          >
            <img src={Close} alt="CANCEL" />
          </button>
        </div>
        <div
          id={ARIA_DESCRIBED_BY}
          className={styles.modalBodySection}
          style={{ maxHeight: `calc(${modalHeight} - 150px)` }}
        >
          <form onSubmit={handleSubmit}>
            <label className={styles.textAreaLabel} htmlFor="feedbackTextArea">
              {isFeedbackPositive
                ? "What did you like about the response?"
                : "What was the issue with the response, how can it be improved?"}
            </label>
            <div className={styles.textAreaContainer}>
              <textarea
                id="feedbackTextArea"
                name="comment"
                value={comment}
                onChange={handleTextareaChange}
                maxLength={200}
              />
              <span className={styles.textLimit}>{comment.length}/200</span>
            </div>

            {!isFeedbackPositive && (
              <div className={styles.checkboxContainer}>
                <label htmlFor="responseHarmful">
                  <div
                    ref={divRefs.responseHarmful}
                    className={styles.svgContainer}
                  >
                    <div
                      className={
                        checkboxValues.responseHarmful
                          ? styles.checkBoxVisible
                          : styles.checkBoxHidden
                      }
                    >
                      <CheckedBoxSVG />
                    </div>
                    <div
                      className={
                        checkboxValues.responseHarmful
                          ? styles.checkBoxHidden
                          : styles.checkBoxVisible
                      }
                    >
                      <UncheckedBoxSVG />
                    </div>
                  </div>
                  <input
                    ref={inputRefs.responseHarmful}
                    type="checkbox"
                    id="responseHarmful"
                    name="responseHarmful"
                    checked={checkboxValues.responseHarmful}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    className={
                      checkboxValues.responseHarmful ? styles.boldSelected : ""
                    }
                  >
                    The response is harmful/unsafe.
                  </span>
                </label>

                <label htmlFor="responseFalse">
                  <div
                    ref={divRefs.responseFalse}
                    className={styles.svgContainer}
                  >
                    <div
                      className={
                        checkboxValues.responseFalse
                          ? styles.checkBoxVisible
                          : styles.checkBoxHidden
                      }
                    >
                      <CheckedBoxSVG />
                    </div>
                    <div
                      className={
                        checkboxValues.responseFalse
                          ? styles.checkBoxHidden
                          : styles.checkBoxVisible
                      }
                    >
                      <UncheckedBoxSVG />
                    </div>
                  </div>
                  <input
                    ref={inputRefs.responseFalse}
                    type="checkbox"
                    id="responseFalse"
                    name="responseFalse"
                    checked={checkboxValues.responseFalse}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    className={
                      checkboxValues.responseFalse ? styles.boldSelected : ""
                    }
                  >
                    The response isn&apos;t true.
                  </span>
                </label>

                <label htmlFor="responseUnhelpful">
                  <div
                    ref={divRefs.responseUnhelpful}
                    className={styles.svgContainer}
                  >
                    <div
                      className={
                        checkboxValues.responseUnhelpful
                          ? styles.checkBoxVisible
                          : styles.checkBoxHidden
                      }
                    >
                      <CheckedBoxSVG />
                    </div>
                    <div
                      className={
                        checkboxValues.responseUnhelpful
                          ? styles.checkBoxHidden
                          : styles.checkBoxVisible
                      }
                    >
                      <UncheckedBoxSVG />
                    </div>
                  </div>
                  <input
                    ref={inputRefs.responseUnhelpful}
                    type="checkbox"
                    id="responseUnhelpful"
                    name="responseUnhelpful"
                    checked={checkboxValues.responseUnhelpful}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    className={
                      checkboxValues.responseUnhelpful
                        ? styles.boldSelected
                        : ""
                    }
                  >
                    The response isn&apos;t helpful.
                  </span>
                </label>

                <label htmlFor="responseReferenceIssue">
                  <div
                    ref={divRefs.responseReferenceIssue}
                    className={styles.svgContainer}
                  >
                    <div
                      className={
                        checkboxValues.responseReferenceIssue
                          ? styles.checkBoxVisible
                          : styles.checkBoxHidden
                      }
                    >
                      <CheckedBoxSVG />
                    </div>
                    <div
                      className={
                        checkboxValues.responseReferenceIssue
                          ? styles.checkBoxHidden
                          : styles.checkBoxVisible
                      }
                    >
                      <UncheckedBoxSVG />
                    </div>
                  </div>
                  <input
                    ref={inputRefs.responseReferenceIssue}
                    type="checkbox"
                    id="responseReferenceIssue"
                    name="responseReferenceIssue"
                    checked={checkboxValues.responseReferenceIssue}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    className={
                      checkboxValues.responseReferenceIssue
                        ? styles.boldSelected
                        : ""
                    }
                  >
                    There was an issue with the supporting references.
                  </span>
                </label>
              </div>
            )}
          </form>
        </div>
        <div className={styles.modalActions}>
          <button
            disabled={comment.length === 0}
            className={styles.modalSubmitButton}
            name="Submit Feedback"
            onClick={handleSubmit}
            role="button"
          >
            Submit Feedback
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModalComponent;
