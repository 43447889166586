import { Fragment, useRef } from "react";

import { InfoCard, type IInfoCard, Hyperlink } from "~/components";
import { LOCAL_LAW_30_URL } from "~/config/config";

import Sparkle from "~/assets/Sparkle.svg";
import Zap from "~/assets/Zap.svg";
import Exclamation from "~/assets/Exclamation.svg";
import RightArrow from "~/assets/RightArrow.svg";

import styles from "./InfoCardList.module.css";

interface IInfoCardList {
  onQuestionReceived: (question: string) => Promise<void>;
  handleFocus: () => void;
}

export const InfoCardList = ({
  onQuestionReceived,
  handleFocus,
}: IInfoCardList): JSX.Element => {
  const handleSendQuestion = async (question: string): Promise<void> => {
    await onQuestionReceived(question);
  };

  const cardList: IInfoCard[] = [
    {
      title: "Example Questions",
      icon: Sparkle,
      onSendQuestion: (question: string) => Promise.resolve(),
      details: [
        {
          info: '"How do I avoid noise violations and complaints for my construction company?"',
          emitQuestion: true,
          detailRef: useRef<HTMLDivElement | null>(null),
        },
        {
          info: '"What information can you help me with?"',
          emitQuestion: true,
          detailRef: useRef<HTMLDivElement | null>(null),
        },
        {
          info: '"How do I apply for the MWBE program?"',
          emitQuestion: true,
          detailRef: useRef<HTMLDivElement | null>(null),
        }
      ],
    },
    {
      title: "Capabilities",
      icon: Zap,
      details: [
        {
          info: "Trained to provide you with official NYC Business information.",
        },
        {
          info: "Will not use the contents of your\u00a0chat\u00a0history to learn new information.",
        },
        {
          info: (
            <Fragment>
              Responds to languages required{"\u00a0"}by{"\u00a0"}
              <Hyperlink
                className={styles.descriptionLink}
                href={LOCAL_LAW_30_URL}
                isTargetBlank
                onFocus={handleFocus}
              >
                Local Law 30
              </Hyperlink>
              .
            </Fragment>
          ),
        },
      ],
    },
    {
      title: "Limitations",
      icon: Exclamation,
      details: [
        {
          info: "May occasionally produce incorrect,\u00a0harmful or biased content.",
        },
        {
          info: "Limited knowledge of the world beyond NYC Business topics.",
        },
        {
          info: "Trained to decline inappropriate\u00a0requests.",
        },
      ],
    },
  ];

  return (
    <div className={styles.cardListContainer}>
      {cardList.map((card, index) => (
        <InfoCard
          key={index}
          title={card.title}
          icon={card.icon}
          details={card.details}
          isFirstCard={index === 0}
          onSendQuestion={handleSendQuestion}
        />
      ))}
    </div>
  );
};

export default InfoCardList;
