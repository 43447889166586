import type React from "react";
import { useRef } from "react";

export enum COND_RENDERED_TEXT_KEY {
  GENERATING_ANSWER = "GENERATING_ANSWER",
  SUPPORTING_REFERENCE = "SUPPORTING_REFERENCE",
  SUPPORTING_REFERENCES = "SUPPORTING_REFERENCES",
  STOP_GENERATION = "STOP_GENERATION",
  NEW_CHAT = "NEW_CHAT",
  RESPONSE_HELPFUL = "RESPONSE_HELPFUL",
  YES = 'YES',
  NO = 'NO',
  VERIFY_TEXT = 'VERIFY_TEXT',
  AND = 'AND',
  DOUBLE_CHECK = 'DOUBLE_CHECK',
  HEADER = 'HEADER'
}

export type ConditionallyRenderedText = Record<
  COND_RENDERED_TEXT_KEY,
  { text: string; ref: React.MutableRefObject<HTMLSpanElement | null> }
>;

// we don't want to use this object in the custom hook
// because we want to create a new reference in the react context
export const initialConditionallyRenderedText = {
  [COND_RENDERED_TEXT_KEY.GENERATING_ANSWER]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.STOP_GENERATION]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCE]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCES]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.NEW_CHAT]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.RESPONSE_HELPFUL]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.YES]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.NO]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.VERIFY_TEXT]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.AND]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.DOUBLE_CHECK]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
  [COND_RENDERED_TEXT_KEY.HEADER]: {
    text: "",
    ref: null as unknown as React.MutableRefObject<HTMLSpanElement | null>,
  },
};

export const useConditionallyRenderedText = (): ConditionallyRenderedText => {
  return {
    [COND_RENDERED_TEXT_KEY.GENERATING_ANSWER]: {
      text: "Generating answer...",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCE]: {
      text: "Supporting reference",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.SUPPORTING_REFERENCES]: {
      text: "Supporting references",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.STOP_GENERATION]: {
      text: "Stop generating",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.NEW_CHAT]: {
      text: "New Chat",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.RESPONSE_HELPFUL]: {
      text: "Was this response helpful?",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.YES]: {
      text: "Yes",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.NO]: {
      text: "No",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.VERIFY_TEXT]: {
      text: "Verify the answer with the MyCity Chatbot’s provided references after the response or on",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.AND]: {
      text: "and",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.DOUBLE_CHECK]: {
      text: "The provided reference(s) can offer more detailed information than the chatbot. As the chatbot is still in test mode, double check the accuracy using the provided links above or",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    [COND_RENDERED_TEXT_KEY.HEADER]: {
      text: "Official website of the City of New York",
      ref: useRef<HTMLSpanElement | null>(null),
    },
    
  };
};
