export enum EnvName {
  DEV = "DEV",
  STG = "STG",
  CSG = "CSG",
  PRD = "PRD",
  LOCAL = "LOCAL",
}

export interface EnvironmentVariableSelectorByEnvName {
  [EnvName.DEV]: string;
  [EnvName.STG]: string;
  [EnvName.CSG]: string;
  [EnvName.PRD]: string;
  [EnvName.LOCAL]: string;
}
