import type { ConversationRequest, FeedbackRequest, UserInfo } from "./models";
import { makeRequest } from "./api.utils";
import {
  AUTH_REQUEST_PATH,
  CONVERSATION_REQUEST_PATH,
  FEEDBACK_REQUEST_PATH,
  GET_REQUEST_INIT,
  HEADERS,
  MIME_TYPES,
  RECAPTCHA_SITE_KEY_REQUEST_PATH,
  REQUEST_METHOD,
} from "./api.const";

export async function getUserInfo(): Promise<UserInfo[]> {
  const response = await fetch(AUTH_REQUEST_PATH);
  if (!response.ok) {
    console.warn(
      "getUserInfo: No identity provider found. Access to chat will be blocked.",
    );
    return [];
  }

  const payload = await response.json();
  return payload;
}

export const conversationApi = (
  options: ConversationRequest,
  abortSignal: AbortSignal,
): Promise<Response> =>
  fetch(CONVERSATION_REQUEST_PATH, {
    method: REQUEST_METHOD.POST,
    headers: {
      [HEADERS.CONTENT_TYPE]: MIME_TYPES.APPLICATION_JSON,
    },
    body: JSON.stringify({
      messages: options.messages,
      sessionUUID: options.sessionUUID,
      chatUUID: options.chatUUID,
      recaptcha_token: options.recaptchaToken,
    }),
    signal: abortSignal,
  });

export const feedbackApi = ({
  chatUUID,
  message,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  recaptchaToken,
  sessionUUID,
  thumbFeedback,
  userFeedback,
}: FeedbackRequest): Promise<Response> =>
  fetch(FEEDBACK_REQUEST_PATH, {
    method: REQUEST_METHOD.POST,
    headers: {
      [HEADERS.CONTENT_TYPE]: MIME_TYPES.APPLICATION_JSON,
    },
    body: JSON.stringify({
      chatUUID,
      message,
      recaptcha_token: recaptchaToken,
      sessionUUID,
      thumbFeedback,
      userFeedback
    }),
  });

export const getRecaptchaSiteKey = (): Promise<Response> =>
  makeRequest(RECAPTCHA_SITE_KEY_REQUEST_PATH, GET_REQUEST_INIT);
