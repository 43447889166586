import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";

import { Hyperlink } from "~/components";

import config, {
   NY_BUSINESS_PAGE, NYC_AI_APPROACH, NYC_LANDING_PAGE,
} from "~/config/config";

import Close from "~/assets/Close.svg";
import styles from "./Modal.module.css";
import CheckedBoxSVG from "~/components/FeedbackModal/CheckedBoxSVG";
import UncheckedBoxSVG from "~/components/FeedbackModal/UncheckedBoxSVG";

const ALERT_DIALOG = "alertdialog";
const ARIA_DESCRIBED_BY = "dialog_desc";
const ARIA_LABELED_BY = "dialog_label";

Modal.setAppElement("#root");

const navigateToBusinessPage = (): void => {
  window.location.href = config.businessPage;
};

const ModalComponent = (): JSX.Element => {
  const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [xOffSet, setXOffSet] = useState<number>(0);
  const [yOffSet, setYOffSet] = useState<number>(0);
  const [modalHeight, setModalHeight] = useState("80vh");
  const [acceptBetaLimitations, setAcceptBetaLimitations] = useState(false);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setIsOpen(false);
      document.body.classList.remove("body-no-scroll");
    },
    [setIsOpen],
  );

  const toggleAcceptBetaLimitations = () => {
    setAcceptBetaLimitations(!acceptBetaLimitations);
  }

  useEffect(() => {
    const {
      height = 300,
      right = 400,
      width = 400,
    } = contentEl?.getBoundingClientRect() ?? {};

    const { width: parentWidth = width } =
      contentEl?.parentElement?.getBoundingClientRect() ?? {};

    if (right > width && parentWidth > width) {
      setXOffSet(Math.round(width / 2));
    }
    setYOffSet(Math.round(height / 2));

    function handleResize(): void {
      const windowHeight = window.innerHeight;
      const newHeight = `${windowHeight * 0.77}px`;
      setModalHeight(newHeight);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contentEl]);

  return (
    <Modal
      aria-describedby={ARIA_DESCRIBED_BY}
      aria-labelledby={ARIA_LABELED_BY}
      className={styles.modalView}
      id={ALERT_DIALOG}
      isOpen={isOpen}
      onRequestClose={navigateToBusinessPage}
      overlayClassName={styles.modalOverlay}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      contentRef={(node) => {
        setContentEl(node);
      }}
      role={ALERT_DIALOG}
      style={{ content: { margin: `-${yOffSet}px 0 0 -${xOffSet}px` } }}
    >
      <div className={styles.modalContent} style={{ height: modalHeight }}>
        <div className={styles.modalHeader}>
          <h2 id={ARIA_LABELED_BY} className={styles.modalHeaderText}>
            MyCity Chatbot<span className={styles.superscriptTitle}> Beta</span>
          </h2>
          <button
            className={styles.modalClose}
            name="Modal Close"
            onClick={navigateToBusinessPage}
            role="button"
          >
            <img src={Close} alt="CANCEL" />
          </button>
        </div>
        <div
          id={ARIA_DESCRIBED_BY}
          className={styles.modalBodySection}
          style={{ maxHeight: `calc(${modalHeight} - 125px` }}
        >
          <h3 className={styles.modalSubHeader}>We are innovating for you.</h3>
          <p>
            We commit to utilizing technology to address the modern needs of New Yorkers.
            The MyCity Chatbot employs Microsoft&apos;s Azure AI to assist with business inquiries
            and is aligned with the city&apos;s AI principles. You can learn more about the city&apos;s
            approach to AI{" "}
            <Hyperlink href={NYC_AI_APPROACH} isTargetBlank>
              here
            </Hyperlink>
            .
          </p>
          <p>
            As a beta product still being tested, it may occasionally provide incomplete or
            inaccurate responses. Verify information with links provided after the response or
            by visiting{" "}
            <Hyperlink href={NY_BUSINESS_PAGE} isTargetBlank>
              MyCity Business
            </Hyperlink>{" "}
            and{" "}
            <Hyperlink href={NYC_LANDING_PAGE} isTargetBlank>
              NYC.gov
            </Hyperlink>.{" "}
            <b>Do not</b> use its responses as legal <b>or</b>{" "}
            professional advice nor provide sensitive information to the Chatbot.
          </p>
          <p>
            Your conversations are not connected to your MyCity account and will be
            permanently deleted after 30 days.
          </p>
          <p>
            <b>reCAPTCHA</b>: MyCity Chatbot is protected by reCAPTCHA
            Enterprise and the Google{" "}
            <Hyperlink href={config.googlePolicy} isTargetBlank>
              Privacy Policy
            </Hyperlink>{" "}
            and{" "}
            <Hyperlink href={config.googleTerms} isTargetBlank>
              Terms of Service
            </Hyperlink>{" "}
            apply.
          </p>
        </div>
        <div className={styles.modalActions}>
          <div className={styles.checkboxContainer}>
            <label htmlFor="acceptBetaLimitations">
              <span>
                {acceptBetaLimitations ? <CheckedBoxSVG/> : <UncheckedBoxSVG/>}
              </span>
              <input
                  type="checkbox"
                  id="acceptBetaLimitations"
                  name="acceptBetaLimitations"
                  checked={acceptBetaLimitations}
                  onClick={() => toggleAcceptBetaLimitations()}
              />
              <span className={styles.checkboxText}>I agree to the MyCity Chatbot's beta limitations</span>
            </label>
          </div>

          <button
              className={styles.modalContinueButton}
              name="Chat Now"
              disabled={!acceptBetaLimitations}
              onClick={handleSubmit}
              role="button">
            Chat Now
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
