import { useContext, useEffect } from "react";
import cx from "classnames";

import { Hyperlink } from "~/components";
import { initTranslate } from "~/google-translate/translate";

import config, { FEEDBACK_FORM_URL } from "~/config/config";
import { globalContext } from "~/context";

import LogoWording from "~/assets/LogoWording.svg";
import styles from "./Header.module.css";
import {COND_RENDERED_TEXT_KEY} from "~/hooks";

export const Header = (): JSX.Element => {
  const {  conditionallyRenderedText } = useContext(globalContext);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    const remove = initTranslate();

    return remove;
  }, []);

  return (
    <header
      className={styles.header}
      role={"banner"}
    >
      <div className={styles.headerWrapper}>
        <a className={styles.logoBar} href={config.myCityPage}>
          <img
            src={LogoWording}
            className={styles.nycLogo}
            alt="NYC My City Logo"
          />
          {
              conditionallyRenderedText[COND_RENDERED_TEXT_KEY.HEADER].ref?.current?.textContent
              ??
              conditionallyRenderedText[COND_RENDERED_TEXT_KEY.HEADER].text
          }
        </a>
        <div className={styles.navBar}>
          <div className={styles.navTitle}>
            Chatbot
            <span className={styles.superscriptTitle}>Beta</span>
          </div>
          <div className={styles.languageBar}>
            <div id="google_translate_element"></div>
          </div>
        </div>
      </div>
      <Hyperlink
          className={styles.feedbackFormButton}
          href={FEEDBACK_FORM_URL}
        isTargetBlank
      >
        FEEDBACK
      </Hyperlink>
    </header>
  );
};
