import type React from "react";
import {
  initialConditionallyRenderedText,
  type ConditionallyRenderedText,
} from "~/hooks/useConditionallyRenderedText";
import { createContext } from "react";

export interface AppContextType {
  conditionallyRenderedText: ConditionallyRenderedText;
  isScreen1080: boolean;
  isScreen760: boolean;
  shouldDisplayHeader: boolean;
  setShouldDisplayHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContext = {
  conditionallyRenderedText: initialConditionallyRenderedText,
  isScreen1080: true,
  isScreen760: true,
  shouldDisplayHeader: true,
  setShouldDisplayHeader: () => {},
};

export const globalContext = createContext<AppContextType>(initialContext);
