import { useState, useEffect } from "react";
import { getRecaptchaSiteKey } from "~/api";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

import "./ReCaptcha.css";

export const ReCaptcha = (): JSX.Element => {
  const [siteKey, setSiteKey] = useState("");

  useEffect(() => {
    const effect = async (): Promise<void> => {
      const response = await getRecaptchaSiteKey();
      const json = await response.json();
      window.siteKey = json.site_key;
      setSiteKey(json.site_key);
    };

    void effect();
  }, []);

  return (
    <div>
      <div id="recaptcha-container"></div>
      {!siteKey ? (
        <div>SiteKey not loaded yet.</div>
      ) : (
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
          <GoogleReCaptcha
            onVerify={(token) => {
              console.log("reCAPTCHA loaded: ", token);
            }}
          />
        </GoogleReCaptchaProvider>
      )}
    </div>
  );
};
