import cx from "classnames";

export interface SvgType {
  ariaLabel?: string;
  classNameActive?: string;
  classNameBase?: string;
  classNameUnSelected?: string;
  fill?: string;
  height: string;
  isActive: boolean;
  unSelected: boolean;
  gId?: string;
  pathD: string;
  pathId?: string;
  viewBox?: string;
  width: string;
}

export const Svg = ({
  ariaLabel = "",
  classNameActive = "",
  classNameBase = "",
  classNameUnSelected = "",
  fill = "none",
  height = "24",
  isActive = false,
  unSelected = false,
  gId = "",
  pathD,
  pathId = "",
  viewBox = "0 0 24 24",
  width = "24",
}: SvgType): JSX.Element => {

  return (
    <svg
      
      aria-label={ariaLabel}
      className={cx(classNameBase, {
        [classNameActive]: isActive,
        [classNameUnSelected]: unSelected,
      })}
      fill={fill}
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id={gId}>
        <path id={pathId} d={pathD} />
      </g>
    </svg>
  );
};
