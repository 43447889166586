import { GA4_SCRIPT_SOURCE } from "~/config/config";

export const initGA4 = (): void => {
  if (GA4_SCRIPT_SOURCE) {
    const ga4Script = document.createElement("script");
    ga4Script.async = true;
    ga4Script.type = "text/javascript";
    ga4Script.src = GA4_SCRIPT_SOURCE;

    document.body.appendChild(ga4Script);
  }
};

export const sendSubmitQuestionGa4CustomEvent = (
  sessionId: string,
  chatId: string,
  questionNum: number,
): void => {
  const options = {
    event_category: "general",
    event_label: "Chatbot Question Submission",
    value: `SessionId: ${sessionId}, ChatId: ${chatId}, Question Number: ${questionNum}`,
  };

  // @ts-expect-error gtag is not defined in the codebase as it will be in the browser global context
  if (typeof gtag === "function") {
    // @ts-expect-error gtag is not defined in the codebase as it will be in the browser global context
    gtag("event", "submit_question", options);
    // @ts-expect-error window.gtag is not on the window object as it will be added by the gtag script
  } else if (typeof window.gtag === "function") {
    // @ts-expect-error window.gtag is not on the window object as it will be added by the gtag script
    window.gtag("event", "submit_question", options);
  }
};
