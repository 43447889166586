import { EnvName } from "./config.type";
import type { EnvironmentVariableSelectorByEnvName } from "./config.type";

export const BUSINESS_SET_HOME_URLS: EnvironmentVariableSelectorByEnvName = {
  [EnvName.DEV]: "https://business-dev.csc.nycnet/nycbusiness/",
  [EnvName.STG]: "https://business-stg.csc.nycnet/nycbusiness/",
  [EnvName.CSG]: "https://nyc-business.nyc.gov/nycbusiness/",
  [EnvName.PRD]: "https://nyc-business.nyc.gov/nycbusiness/",
  [EnvName.LOCAL]: "https://nyc-business.nyc.gov/nycbusiness/",
};

export const FEEDBACK_FORM_URLS: EnvironmentVariableSelectorByEnvName = {
  [EnvName.DEV]:
    "https://nycgov-csg.cityapps.nycnet/assets/home/html/chatbot/feedback.html",
  [EnvName.STG]:
    "https://nycgov-stg.cityapps.nycnet/assets/home/html/chatbot/feedback.html",
  [EnvName.CSG]:
    "https://nycgov-csg.cityapps.nycnet/assets/home/html/chatbot/feedback.html",
  [EnvName.PRD]: "https://www.nyc.gov/assets/home/html/chatbot/feedback.html",
  [EnvName.LOCAL]:
    "https://nycgov-csg.cityapps.nycnet/assets/home/html/chatbot/feedback.html",
};

export const GA4_SCRIPT_SOURCES: EnvironmentVariableSelectorByEnvName = {
  [EnvName.DEV]:
    "https://nycgov-dev.cityapps.nycnet/assets/home/js/google/ga-chatbot.js",
  [EnvName.STG]:
    "https://nycgov-stg.cityapps.nycnet/assets/home/js/google/ga-chatbot.js",
  [EnvName.CSG]:
    "https://nycgov-stg.cityapps.nycnet/assets/home/js/google/ga-chatbot.js",
  [EnvName.PRD]: "https://www.nyc.gov/assets/home/js/google/ga-chatbot.js",
  [EnvName.LOCAL]: "",
};
