import { Outlet } from "react-router-dom";

import styles from "./Layout.module.css";

const Layout = (): JSX.Element => (
  <div className={styles.layout}>
    <Outlet />
  </div>
);

export default Layout;
